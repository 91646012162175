<template>
  <v-card outlined>
    <v-card-text>
      <v-data-table
        flat 
        show-expand
        fixed-header
        disable-sort
        disable-pagination
        hide-default-footer
        class="title mb-3" 
        loader-height="1"
        :loading="loading"
        :headers="headers" 
        :items="orders.data"
      >
        <template v-slot:item.customer="{ item }">
          {{ item.customer.name }}
        </template> 

        <template v-slot:item.distribution_point="{ item }">
          {{ item.distribution_point.name }}
        </template>

        <template v-slot:item.payment="{ item }">
          <v-btn
            small
            color="error"
            class="ttn caption"
            @click="order = item"
          >
            Make payment
          </v-btn>
        </template> 

        <template v-slot:expanded-item="{ headers, item }">
          <td 
            :colspan="headers.length"
            class="pa-5"
          >
            <v-card outlined>
              <v-card-text>
                <v-data-table
                  flat 
                  fixed-header
                  disable-sort
                  disable-pagination
                  hide-default-footer
                  class="title mb-3" 
                  loader-height="1"
                  :headers="nestedHeaders" 
                  :items="Object.values(item.order_items)"
                ></v-data-table>
              </v-card-text>
            </v-card>
          </td>
        </template>
      </v-data-table>

      <app-pagination
        v-if="orders.meta"
        :meta="orders.meta"
        @pageChanged="pageChanged"
      ></app-pagination>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      page: 1,
      expanded: [],
      loading: null,
      headers: [
        { text: 'Customer', value: 'customer' },
        { text: 'Distribution point', value: 'distribution_point' },
        { text: 'Total', value: 'total' },
        { text: 'Amount paid', value: 'paid' },
        { text: 'Balance', value: 'balance' },
        // { text: 'Payments', value: 'payment' },
      ],
      nestedHeaders: [
        { text: 'Description', value: 'description' },
        { text: 'Unit price', value: 'unit_price' },
        { text: 'Quantity', value: 'quantity' },
        { text: 'Subtotal', value: 'subtotal' },
      ]
    }
  },

  computed: {
    ...mapGetters({
      orders: 'getRetailOrders'
    })
  },

  methods: {
    ...mapActions([
      'setRetailOrders'
    ]),

    loadRetailOrders () {
      this.setRetailOrders({
        params: {
          page: this.page
        }
      }).finally(() => {
        this.loading = false
      })
    },

    pageChanged (page) {
      this.page = page
      this.loading = true
      this.loadRetailOrders()
    },
  },

  mounted () {
    this.loadRetailOrders()
  }
}
</script>